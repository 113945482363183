import React from 'react';

interface Props {}

const gameModes = () => {
  return (
    <div>
      <div>hi </div>
    </div>
  );
};

export default gameModes;
